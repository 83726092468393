<template>
  <div class="mt-5">
    <div class="system" v-for="system in project.systems" :key="system.id">
      <!-- <div class="system__refresh" v-if="system.type == 20">
        <button
          class="btn shadow-sm btn-primary round"
          @click="sync(system)"
          v-bind:disabled="system.loading"
        >
          <i class="far fa-sync" />
        </button>
      </div> -->
      <div class="system__top">
        <div class="system__top__state system-state">
          <img
            :src="
              require(`@/assets/images/system/pump/pump-${system.state}.svg`)
            "
            v-if="system.type == 5"
          />
          <img
            :src="require(`@/assets/images/system/machine-${system.state}.svg`)"
            v-if="system.type == 20"
          />

          <div class="system-state__secondary-state" v-if="system.hand">
            <img src="../assets/images/icons/hand.svg" />
          </div>
        </div>
        <div class="system__top__info">
          <div class="system__top__info__small">
            <small
              ><span class="text-muted">{{ project.name }}</span>
              <span class="text-primary">&nbsp;&nbsp;|&nbsp;&nbsp;</span>
              <strong class="text-uppercase">{{
                $filters.formatDate(system.updateDate)
              }}</strong></small
            >
          </div>
          <div class="system__top__info__name">
            {{ system.name }}
          </div>
        </div>
      </div>

      <div
        class="system__bottom"
        v-if="system.state != 40 && system.state != 50"
      >
        <ul class="list-inline mb-0">
          <li
            class="list-inline-item"
            v-for="property in system.properties"
            :key="property"
          >
            <i
              class="fal fa-tachometer-average"
              v-if="property.unit == 'Bar'"
            ></i>
            <i class="fal fa-bolt" v-if="property.unit == 'kW'"></i>
            <i class="fal fa-heart-rate" v-if="property.unit == 'Hz'"></i>
            <i class="fal fa-tachometer-alt" v-if="property.unit == 'm/h'"></i>
            <i class="fal fa-ruler-horizontal" v-if="property.unit == 'm'"></i>
            <i class="fal fa-ruler-vertical" v-if="property.unit == 'cm'"></i>
            <i
              class="fal fa-dewpoint"
              v-if="property.unit == 'm³/h' || property.unit == 'l/m'"
            ></i>
            <small
              >{{ $filters.number(property.value, 1, " ", system.updateDate) }}
              {{ property.unit }}</small
            >
          </li>
        </ul>
      </div>

      <router-link
        v-bind:to="'/projects/' + projectId + '/' + system.id"
      ></router-link>
    </div>
  </div>

  <!-- <pre>{{ project }}</pre> -->
</template>

<script>
import { onMounted, onUnmounted, ref } from "vue";
import { useRoute } from "vue-router";
import { useStore } from "vuex";
import Projects from "../api/projects";
import Systems from "../api/systems";

export default {
  setup(_, { emit }) {
    const route = useRoute();
    const projectId = route.params.id;
    const project = ref({});
    let timeout; // Variable to store the timeout identifier
    let syncing = false;
    const store = useStore();
    const minInterval = 15000; // Minimum interval of 10 seconds

    function sync(system) {
      system.loading = true;
      Systems.sync(system.id).then(() => {
        system.loading = false;
      });
    }

    const syncProject = () => {
      if (syncing) {
        return;
      }

      syncing = true;
      const startTime = Date.now(); // Record the start time of the request

      Projects.getByIdLive(projectId)
        .then((res) => {
          project.value = res.data;
          syncing = false;
          const duration = Date.now() - startTime; // Calculate the duration of the request

          if (duration >= minInterval) {
            // If the request took longer than or equal to minInterval, immediately call syncProject
            syncProject();
          } else {
            // Otherwise, schedule the next call to syncProject to ensure at least minInterval has passed
            clearTimeout(timeout);
            timeout = setTimeout(syncProject, minInterval - duration);
          }
        })
        .catch((error) => {
          console.log(error);
          syncing = false;
          // Schedule the next sync with the minimum interval in case of an error
          clearTimeout(timeout);
          timeout = setTimeout(syncProject, minInterval);
        });
    };

    onMounted(() => {
      Projects.getById(projectId)
        .then((res) => {
          project.value = res.data;
        })
        .catch((error) => {
          console.log(error);
        });

      // Initial call to start the process immediately
      syncProject();

      store.commit("setShowBackButton", store.state.hasMultipleProjects);
      emit("show-back-button");
    });

    onUnmounted(() => {
      clearTimeout(timeout); // Clear the timeout when the component is unmounted
    });

    return { projectId, project, sync };
  },
};
</script>

<style>
</style>