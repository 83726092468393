<template>
  <LoadingModal :show="runningCommand" />
  <SystemInfo :system="system" />

  <div
    class="row mt-5 bg-light pb-4 text-center flex-grow-1"
    v-if="system && system.name && system.state != 40 && system.state != 50"
  >
    <div>
      <div class="btn-group mt-n4" v-if="!system.readOnly">
        <button
          class="btn btn-primary"
          @click="
            command = 'on';
            openModal();
          "
          v-bind:disabled="runningCommand"
        >
          {{ $t("button.on") }}
        </button>
        <button
          class="btn btn-primary"
          @click="reset"
          v-bind:disabled="runningCommand"
        >
          {{ $t("button.reset") }}
        </button>
        <button
          class="btn btn-primary"
          @click="
            command = 'off';
            openModal();
          "
          v-bind:disabled="runningCommand"
        >
          {{ $t("button.off") }}
        </button>
      </div>

      <div class="row mt-4 justify-content-center">
        <div class="col-6" v-if="system.flowCv">
          <div class="box">
            <div class="box__value">
              <div class="box__value__title">{{$t('system.flow')}}</div>
              <div class="box__value__val">
                {{
                  $filters.number(
                    parseFloat(system.flowCv),
                    1,
                    " ",
                    system.updateDate
                  )
                }}
                m<sup>3</sup>/h
              </div>
            </div>
            <div class="box__value">
              <div class="box__value__title">{{$t('system.totalFlow')}}</div>
              <div class="box__value__val">
                {{
                  $filters.number(system.flowTotalCv, 0, " ", system.updateDate)
                }}
                m<sup>3</sup>
              </div>
            </div>
          </div>
        </div>
        <div class="col-6">
          <div class="box">
            <div class="box__value">
              <div class="box__value__title">{{$t('system.currentPressure')}}</div>
              <div class="box__value__val">
                {{
                  $filters.number(system.pressureCv, 1, " ", system.updateDate)
                }}
                bar
              </div>
            </div>
            <div class="box__value" v-if="system.pressureSp">
              <div class="box__value__title">{{$t('system.desiredPressure')}}</div>
              <div class="box__value__val">
                {{
                  $filters.number(system.pressureSp, 1, " ", system.updateDate)
                }}
                bar
              </div>
            </div>

            <div class="box_action" v-if="system.pressureSp">
              <button
                type="button"
                class="btn btn-dark btn-sm btn-rounded"
                @click="
                  command = 'pressure';
                  openModal();
                "
                v-if="!system.readOnly"
              >
                {{$t('button.edit')}}
              </button>
            </div>
          </div>

          <CommandModal>
            <template #body>
              <div v-if="command == 'on'">{{$t('system.confirmationStartPump')}}</div>
              <div v-if="command == 'off'">{{$t('system.confirmationStopPump')}}</div>
              <div v-if="command == 'pressure'">
                <div class="row">
                  <div class="col gx-0 text-end">
                    <i
                      class="fal fa-minus fa-lg"
                      @click="decrementPressure"
                    ></i>
                  </div>
                  <div class="col">
                    <input
                      type="number"
                      step=".1"
                      min="0"
                      max="1000"
                      v-model="pressure"
                      style="
                        width: 60px;
                        border: 0;
                        border-bottom: 1px solid;
                        text-align: center;
                      "
                    />
                  </div>
                  <div class="col gx-0 text-start">
                    <i class="fal fa-plus fa-lg" @click="incrementPressure"></i>
                  </div>
                </div>
              </div>
            </template>
            <template #action>
              <button
                type="button"
                class="btn btn-primary btn-sm btn-rounded"
                v-if="command == 'pressure'"
                @click="changePressure"
              >
                {{$t("system.editPressure")}}
              </button>
              <button
                type="button"
                class="btn btn-primary btn-sm btn-rounded"
                v-if="command == 'on'"
                @click="turnOn"
              >
                {{$t("button.start")}}
              </button>
              <button
                type="button"
                class="btn btn-primary btn-sm btn-rounded"
                v-if="command == 'off'"
                @click="turnOff"
              >
                {{$t("button.stop")}}
              </button>
            </template>
          </CommandModal>
        </div>
      </div>

      <div class="mt-4">
        <div class="row small">
          <div class="col-6" v-for="data in additionalData" :key="data">
            <div class="row g-1">
              <div class="col-6 text-end text-muted">{{ data.label }}</div>
              <div class="col-6">
                <strong>{{ data.value }} {{ data.unit }}</strong>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- <pre>{{ system }}</pre> -->
  <teleport to="#headerAction" v-if="system && system.name">
    <div>
      <router-link
        v-bind:to="
          '/projects/' + system.project.id + '/' + system.id + '/alarms'
        "
      >
        <img
          src="../../assets/images/icons/bell.svg"
          alt="bell"
          width="23"
          v-if="system.state == 30"
        />
        <i class="fas fa-bell fa-lg text-dark" v-else></i>
      </router-link>
    </div>
  </teleport>
</template>

<script>
import { computed, onMounted, onUnmounted, ref, getCurrentInstance } from "vue";
import { useRoute } from "vue-router";
import Systems from "../../api/systems";
import LoadingModal from "../LoadingModal";
import CommandModal from "../CommandModal";
import SystemInfo from "./Shared/SystemInfo";
import { Modal } from "bootstrap";
import i18n from '../../i18n';

export default {
  components: { SystemInfo, LoadingModal, CommandModal },
  props: ["currentSystem"],
  setup(props) {
    const system = ref(props.currentSystem);
    const pressure = ref(0);
    const command = ref();
    const updateFreq = 14000;
    const runningCommand = ref(false);
    const route = useRoute();
    const { proxy } = getCurrentInstance();
    const $toast = proxy.$toast;
    const $filters = proxy.$filters;
    const systemId = route.params.systemId;
    let interval = "";
    let syncing = false;
    const modal = ref({});

    onMounted(() => {
      modal.value = new Modal(document.getElementById("commandModal"));
      interval = setInterval(sync, updateFreq);
    });

    onUnmounted(() => {
      clearInterval(interval);
    });

    function openModal() {
      pressure.value = system.value.pressureSp;
      console.log(pressure.value);
      modal.value.show();
    }

    function decrementPressure() {
      pressure.value = (pressure.value - 0.1).toFixed(1);
    }

    function incrementPressure() {
      pressure.value = (parseFloat(pressure.value) + 0.1).toFixed(1);
    }

    function sync() {
      if(syncing) {
          return;
      }

      syncing = true;
      Systems.sync(systemId)
        .then((res) => {
          system.value = res.data;
          runningCommand.value = false;
          syncing = false;
        })
        .catch((error) => {
          syncing = false;
          console.log(error.response.data);
        });
    }

    function turnOn() {
      runningCommand.value = true;
      Systems.turnOn(systemId)
        .then(() => {
          $toast.success(i18n.global.t('system.pumpStarted'), {
            duration: 24000,
          });
          runningCommand.value = false;
          modal.value.hide();
        })
        .catch((error) => {
           $toast.error(i18n.global.t('system.commandFailed') + ":<br>" + error.response.data);
          runningCommand.value = false;
        });
    }

    function turnOff() {
      runningCommand.value = true;
      Systems.turnOff(systemId)
        .then(() => {
          $toast.success(i18n.global.t('system.pumpTurnedOff'), {
            duration: 24000,
          });
          runningCommand.value = false;
          modal.value.hide();
        })
        .catch((error) => {
           $toast.error(i18n.global.t('system.commandFailed') + ":<br>" + error.response.data);
          runningCommand.value = false;
        });
    }

    function reset() {
      runningCommand.value = true;
      Systems.reset(systemId)
        .then(() => {
          $toast.success(i18n.global.t('system.pumpRestored'), {
            duration: 24000,
          });
          runningCommand.value = false;
        })
        .catch((error) => {
           $toast.error(i18n.global.t('system.commandFailed') + ":<br>" + error.response.data);
          runningCommand.value = false;
        });
    }

    function changePressure() {
      runningCommand.value = true;
      Systems.changePressure(systemId, pressure.value)
        .then(() => {
          $toast.success(i18n.global.t('system.pressureUpdated'));
          system.value.pressureSp = pressure.value;

          runningCommand.value = false;
          modal.value.hide();
        })
        .catch((error) => {
           $toast.error(i18n.global.t('system.commandFailed') + ":<br>" + error.response.data);
          runningCommand.value = false;
        });
    }

    const additionalData = computed(() => {
      var data = [];

      if (system.value.frequencyCv) {
        data.push({
          label: i18n.global.t('system.frequency'),
          value: $filters.number(
            system.value.frequencyCv,
            1,
            " ",
            system.value.updateDate
          ),
          unit: "Hz",
        });
      }

      if (system.value.energykWhCv && system.value.energyMWhCv) {
        data.push({
          label: i18n.global.t('system.energy'),
          value: $filters.number(
            parseFloat(system.value.energykWhCv) +
              1000 * parseFloat(system.value.energyMWhCv),
            1,
            " ",
            system.value.updateDate
          ),
          unit: "kWh",
        });
      }
      if (system.value.currentCv) {
        data.push({
          label: i18n.global.t('system.current'),
          value: $filters.number(
            system.value.currentCv,
            1,
            " ",
            system.value.updateDate
          ),
          unit: "A",
        });
      }
      if (system.value.runHoursCv) {
        data.push({
          label: i18n.global.t('system.operatingTime'),
          value: $filters.number(
            system.value.runHoursCv,
            0,
            " ",
            system.value.updateDate
          ),
          unit: "h",
        });
      }
      if (system.value.powerCv) {
        data.push({
          label: i18n.global.t('system.effect'),
          value: $filters.number(
            system.value.powerCv,
            1,
            " ",
            system.value.updateDate
          ),
          unit: "kW",
        });
      }

      if (system.value.temp3) {
        data.push({
          label: i18n.global.t('system.temp'),
          value: $filters.number(
            system.value.temp3,
            1,
            " ",
            system.value.updateDate
          ),
          unit: "°C",
        });
      }

      if (system.value.level1) {
        data.push({
          label: i18n.global.t('system.level'),
          value: $filters.number(
            parseFloat(system.value.level1) / 1000,
            3,
            " ",
            system.value.updateDate
          ),
          unit: "m",
        });
      }

      return data;
    });

    return {
      system,
      additionalData,
      turnOn,
      turnOff,
      reset,
      runningCommand,
      pressure,
      openModal,
      decrementPressure,
      incrementPressure,
      changePressure,
      command,
    };
  },
};
</script>
<style>
</style>